import { ERROR_CODES } from "@/shared/util/errorCodes.js";
import { ApiCall } from "../apiBase";

class DatafileReportService {
  async checkReportPasswordRequired(datafileId) {
    let result = await ApiCall({
      method: "post",
      data: {
        query: `
          query checkReportPasswordRequired($datafileId: ID!){
            checkReportPasswordRequired(datafileId: $datafileId)
          }
        `,
        variables: {
          datafileId
        },
        operationName: "checkReportPasswordRequired"
      }
    });
    if (result.data.data && !result.data.errors) {
      return result.data.data.checkReportPasswordRequired;
    } else {
      return true;
    }
  }

  async checkReportPassword(datafileId, pass) {
    let passValid = false;
    const response = await ApiCall({
      method: "post",
      data: {
        query: `
          query checkReportPassword($datafileId: ID!, $pass: String!) {
            checkReportPassword(datafileId: $datafileId, pass: $pass)
          }
        `,
        variables: {
          datafileId,
          pass
        },
        operationName: "checkReportPassword"
      }
    });
    if (response) {
      passValid = response.data.data.checkReportPassword;
    }
    return passValid;
  }

  async getReportDistributionData(datafileId, pass) {
    let distributionData = [];
    const response = await ApiCall({
      method: "post",
      data: {
        query: `
          query getReportDistributionData($datafileId: ID!, $pass: String) {
            getReportDistributionData(datafileId: $datafileId, pass: $pass)
          }
        `,
        variables: {
          datafileId,
          pass
        },
        operationName: "getReportDistributionData"
      }
    });
    if (response && !response.data.errors) {
      distributionData = response.data.data.getReportDistributionData;
    }
    return distributionData;
  }

  async getReportRScore(datafileId, pass) {
    let datafileRScore = 0;
    const response = await ApiCall({
      method: "post",
      data: {
        query: `
          query getReportRScore($datafileId: ID!, $pass: String) {
            getReportRScore(datafileId: $datafileId, pass: $pass)
          }
        `,
        variables: {
          datafileId,
          pass
        },
        operationName: "getReportRScore"
      }
    });
    if (response && !response.data.errors) {
      datafileRScore = response.data.data.getReportRScore;
    }
    return datafileRScore;
  }

  async getReportTrafficLightData(datafileId, pass) {
    let trafficLightData = {};
    const response = await ApiCall({
      method: "post",
      data: {
        query: `
          query getReportTrafficLightData($datafileId: ID!, $pass: String) {
            getReportTrafficLightData(datafileId: $datafileId, pass: $pass) {
              Green
              LightGreen
              Yellow
              Red
              Grey
            }
          }
        `,
        variables: {
          datafileId,
          pass
        },
        operationName: "getReportTrafficLightData"
      }
    });
    if (response && !response.data.errors) {
      trafficLightData = response.data.data.getReportTrafficLightData;
    }
    return trafficLightData;
  }

  async getReportMetadata(datafileId, pass) {
    let reportMetadata = {};
    const response = await ApiCall({
      method: "post",
      data: {
        query: `
          query getReportMetadata($datafileId: ID!, $pass: String) {
            getReportMetadata(datafileId: $datafileId, pass: $pass) {
              includedRecords
              removedRecords
              scoreThresholds {
                R
                TS
                IBS
                PS
                CS
                OES
              }
              uploadMethod
              clientName
              clientLogo
              companyName
              companyLogo
              datafileTitle
              liveStatus
              weighted
              certificateCode
              certificateLevel
              certificateImageURL
              isDisplayQualityScoreWidgets
              createdDate
              reportURL
            }
          }
        `,
        variables: {
          datafileId,
          pass
        },
        operationName: "getReportMetadata"
      }
    });
    if (response && !response.data.errors) {
      reportMetadata = response.data.data.getReportMetadata;
    }
    return reportMetadata;
  }

  async getReportTargetQuotaPercentage(datafileId, pass) {
    let result = await ApiCall({
      method: "post",
      data: {
        query: `
          query getReportTargetQuotaPercentage($datafileId: ID!, $pass: String) {
            getReportTargetQuotaPercentage(datafileId: $datafileId, pass: $pass)
          }
        `,
        variables: {
          datafileId,
          pass
        },
        operationName: "getReportTargetQuotaPercentage"
      }
    });

    if (
      result?.data?.data?.getReportTargetQuotaPercentage &&
      !result?.data?.errors
    ) {
      return result.data.data.getReportTargetQuotaPercentage;
    } else if (
      result?.data?.errors[0]?.extensions?.code ===
      ERROR_CODES.QUOTA_SETTINGS_NOT_SAVED
    ) {
      return result?.data?.errors[0]?.extensions?.code;
    } else {
      throw Error(result?.data?.errors[0]?.extensions?.code);
    }
  }

  async getReportRecordTable(datafileId, allDatarecords, pass) {
    let records = [];
    const response = await ApiCall({
      method: "post",
      data: {
        query: `
          query getReportRecordTable($datafileId: ID!, $allDatarecords: Boolean, $pass: String) {
            getReportRecordTable(datafileId: $datafileId, allDatarecords: $allDatarecords, pass: $pass) {
              recordId
              isIncluded
              rScore
              pScore
              cScore
              iScore
              timeScore
              ibScore
              oeScore
              weight
              collectingTime
              cleaningTime
              cleaningMethod
            }
          }
        `,
        variables: {
          datafileId,
          allDatarecords,
          pass
        },
        operationName: "getReportRecordTable"
      }
    });
    if (response && !response.data.errors) {
      records = response.data.data.getReportRecordTable;
    }
    return records;
  }

  async getManageReportData(datafileId) {
    let reportManageData = null;
    const response = await ApiCall({
      method: "post",
      data: {
        query: `
          query getManageReportData($datafileId: ID!) {
            getManageReportData(datafileId: $datafileId) {
              client {
                name
                logo
              }
              isDisplayQualityScoreWidgets
              isPasswordEnable
              password
              reportURL
              isDisplayShortReport
            }
          }
        `,
        variables: {
          datafileId
        }
      }
    });

    if (response && !response.data.errors) {
      reportManageData = response.data.data.getManageReportData;
    }
    return reportManageData;
  }

  async setDatafileReportManageData(manageData) {
    const res = await ApiCall({
      method: "post",
      data: {
        query: `
          mutation setManageReprotData($manageData: ReportManageDataInput!) {
            setManageReprotData(reportManageData: $manageData) {
              status
              message
            }
          }
         `,
        variables: {
          manageData
        }
      }
    });
    if (res?.data?.data && !res.data.errors) {
      return res.data.data.setManageReprotData;
    }
    return false;
  }

  async getReportAverageTimeScore(datafileId, pass) {
    let tScores = [0, 0];
    const response = await ApiCall({
      method: "post",
      data: {
        query: `
          query getReportAverageTimeScore($datafileId: ID!, $pass: String) {
            getReportAverageTimeScore(datafileId: $datafileId, pass: $pass)
          }
        `,
        variables: {
          datafileId,
          pass
        },
        operationName: "getReportAverageTimeScore"
      }
    });

    tScores = response.data.data.getReportAverageTimeScore;
    return tScores;
  }

  async getReportAverageItemBatteryScore(datafileId, pass) {
    let ibScores = [0, 0];
    const response = await ApiCall({
      method: "post",
      data: {
        query: `
          query getReportAverageItemBatteryScore($datafileId: ID!, $pass: String) {
            getReportAverageItemBatteryScore(datafileId: $datafileId, pass: $pass)
          }
        `,
        variables: {
          datafileId,
          pass
        },
        operationName: "getReportAverageItemBatteryScore"
      }
    });
    if (response && !response.data.errors) {
      ibScores = response.data.data.getReportAverageItemBatteryScore;
    }
    return ibScores;
  }

  async getReportAverageOpenEndedScore(datafileId, pass) {
    let oeScores = [0, 0];
    const response = await ApiCall({
      method: "post",
      data: {
        query: `
          query getReportAverageOpenEndedScore($datafileId: ID!, $pass: String) {
            getReportAverageOpenEndedScore(datafileId: $datafileId, pass: $pass)
          }
        `,
        variables: {
          datafileId,
          pass
        },
        operationName: "getReportAverageOpenEndedScore"
      }
    });
    if (response && !response.data.errors) {
      oeScores = response.data.data.getReportAverageOpenEndedScore;
    }
    return oeScores;
  }

  async getReportAveragePredictionScore(datafileId, pass) {
    let pScores = [0, 0];
    const response = await ApiCall({
      method: "post",
      data: {
        query: `
          query getReportAveragePredictionScore($datafileId: ID!, $pass: String) {
            getReportAveragePredictionScore(datafileId: $datafileId, pass: $pass)
          }
        `,
        variables: {
          datafileId,
          pass
        },
        operationName: "getReportAveragePredictionScore"
      }
    });
    if (response && !response.data.errors) {
      pScores = response.data.data.getReportAveragePredictionScore;
    }
    return pScores;
  }

  async getReportAverageCertaintyScore(datafileId, pass) {
    let cScores = [0, 0];
    const response = await ApiCall({
      method: "post",
      data: {
        query: `
          query getReportAverageCertaintyScore($datafileId: ID!, $pass: String) {
            getReportAverageCertaintyScore(datafileId: $datafileId, pass: $pass)
          }
        `,
        variables: {
          datafileId,
          pass
        },
        operationName: "getReportAverageCertaintyScore"
      }
    });
    if (response && !response.data.errors) {
      cScores = response.data.data.getReportAverageCertaintyScore;
    }
    return cScores;
  }

  async getReportAverageInformationScore(datafileId, pass) {
    let iScores = [0, 0];
    const response = await ApiCall({
      method: "post",
      data: {
        query: `
          query getReportAverageInformationScore($datafileId: ID!, $pass: String) {
            getReportAverageInformationScore(datafileId: $datafileId, pass: $pass)
          }
        `,
        variables: {
          datafileId,
          pass
        },
        operationName: "getReportAverageInformationScore"
      }
    });
    if (response && !response.data.errors) {
      iScores = response.data.data.getReportAverageInformationScore;
    }
    return iScores;
  }

  async getReportAverageSocialDesirabilityScore(datafileId, pass) {
    let sdScores = [0, 0];
    const response = await ApiCall({
      method: "post",
      data: {
        query: `
          query getReportAverageSocialDesirabilityScore($datafileId: ID!, $pass: String) {
            getReportAverageSocialDesirabilityScore(datafileId: $datafileId, pass: $pass)
          }
        `,
        variables: {
          datafileId,
          pass
        },
        operationName: "getReportAverageSocialDesirabilityScore"
      }
    });
    if (response && !response.data.errors) {
      sdScores = response.data.data.getReportAverageSocialDesirabilityScore;
    }
    return sdScores;
  }

  async getReportDatafile(datafileId, pass) {
    let datafile = null;
    let result = await ApiCall({
      method: "post",
      data: {
        query: `
          query getReportDatafile($datafileId: ID!, $pass: String){
            getReportDatafile(datafileId: $datafileId, pass: $pass) {
              title
              isLiveCleaning
              isWeighted
              certificateLevel
              cleaningSettings {
                scoreThresholds {
                  key
                  name
                  isEnable
                  threshold
                }
                targetSampleSize
              }
              cleaningMethod
              isLiveCleaning
              numberOfRecords
              numberOfCleanedRecords
              isCleaned
              markThreshold
            }
          }
        `,
        variables: {
          datafileId,
          pass
        },
        operationName: "getReportDatafile"
      }
    });

    if (result?.data?.data?.getReportDatafile && !result?.data?.errors) {
      datafile = result.data.data.getReportDatafile;
    } else {
      throw Error(result?.data?.errors[0]?.extensions?.code);
    }
    return datafile;
  }

  async getCertificateVerification(certificateCode) {
    let certificateVerificationObject = null;
    let result = await ApiCall({
      method: "post",
      data: {
        query: `
          query getCertificateVerification($certificateCode: String!){
            getCertificateVerification(certificateCode: $certificateCode) {
              isVerified
              datafileTitle
              datafileId
              clientName
              companyName
              certificateCode
              certificateLevel
              createdDate
            }
          }
        `,
        variables: {
          certificateCode
        },
        operationName: "getCertificateVerification"
      }
    });

    if (
      result?.data?.data?.getCertificateVerification &&
      !result?.data?.errors
    ) {
      certificateVerificationObject =
        result.data.data.getCertificateVerification;
    } else {
      throw Error(result?.data?.errors[0]?.extensions?.code);
    }
    return certificateVerificationObject;
  }

  async checkReportTypeIsShort(datafileId) {
    const response = await ApiCall({
      method: "post",
      data: {
        query: `
          query checkReportTypeIsShort($datafileId: ID!) {
            checkReportTypeIsShort(datafileId: $datafileId)
          }
        `,
        variables: {
          datafileId
        },
        operationName: "checkReportTypeIsShort"
      }
    });
    if (response && !response.data.errors) {
      return response.data.data.checkReportTypeIsShort;
    } else {
      return false;
    }
  }
}

export default new DatafileReportService();
